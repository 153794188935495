import { employeeRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  employees: [],
  current_employee: {},
}

export const getters = {
  employees(state) {
    return state.employees
  },
  current_employee(state) {
    return state.current_employee
  },
}

export const mutations = {
  SET_EMPLOYEES(state, newValue) {
    state.employees = newValue
  },
  SET_CURRENT_EMPLOYEE(state, newValue) {
    state.current_employee = newValue
  },
}

export const actions = {
  // Get Employees.
  getEmployees({ commit, dispatch }, id) {
    return employeeRepo.get(id).then(
      (response) => {
        if (id) {
          commit('SET_CURRENT_EMPLOYEE', response.data)
        } else {
          commit('SET_EMPLOYEES', response.data)
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create Employee.
  createEmployee({ commit, dispatch }, employee) {
    return employeeRepo.post(employee).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_CURRENT_EMPLOYEE', response.data)
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete Employee.
  deleteEmployee({ state, commit, dispatch }, id) {
    return employeeRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_EMPLOYEE', {})
          commit(
            'SET_EMPLOYEES',
            state.employees.filter((e) => parseInt(e.id) !== parseInt(id))
          )
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
