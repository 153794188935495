import createApiRepository from './api-repository'
import user from './user'
import organizations from './organizations'

export const userRepo = user
export const employeeRepo = createApiRepository('employees')
export const departmentRepo = createApiRepository('departments')
export const jobTitleRepo = createApiRepository('job_titles')
export const workPlanRepo = createApiRepository('work_plans')
export const orgRepo = organizations
export const attendanceTypeRepo = createApiRepository('attendance_types')
export const leaveTypeRepo = createApiRepository('leave_types')
export const payrollExtraRepo = createApiRepository('payroll_extras')
