import { leaveTypeRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  leave_types: [],
  current_leave_type: {
    type: '',
    name: '',
    is_active: true,
  },
}

export const getters = {
  leave_types(state) {
    return state.leave_types
  },
  current_leave_type(state) {
    return state.current_leave_type
  },
}

export const mutations = {
  SET_LEAVE_TYPES(state, newValue) {
    state.leave_types = newValue
  },
  SET_CURRENT_LEAVE_TYPE(state, newValue) {
    state.current_leave_type = newValue
  },
}

export const actions = {
  // Get LeaveTypes.
  getLeaveTypes({ commit, dispatch }, id) {
    return leaveTypeRepo.get(id).then(
      (response) => {
        if (id) {
          commit('SET_CURRENT_LEAVE_TYPE', response.data)
        } else {
          commit('SET_LEAVE_TYPES', response.data)
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create LeaveType.
  createLeaveType({ commit, dispatch }, leave_type) {
    return leaveTypeRepo.post(leave_type).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_LEAVE_TYPE', response.data.data)
          if (leave_type.id) {
            commit(
              'SET_LEAVE_TYPES',
              state.leave_types.map((j) => {
                if (parseInt(j.id) === parseInt(leave_type.id)) {
                  return response.data.data
                }
                return j
              })
            )
          } else {
            commit('SET_LEAVE_TYPES', [
              ...state.leave_types,
              response.data.data,
            ])
          }
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete LeaveType.
  deleteLeaveType({ commit, dispatch }, id) {
    return leaveTypeRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_CURRENT_LEAVE_TYPE', {})
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
