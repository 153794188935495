import { workPlanRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  work_plans: [],
  current_work_plan: {
    type: 'daily',
    name: '',
    schedule: {
      sun: {
        off: true,
      },
      mon: {
        off: true,
      },
      tue: {
        off: true,
      },
      wed: {
        off: true,
      },
      thu: {
        off: true,
      },
      fri: {
        off: true,
      },
      sat: {
        off: true,
      },
    },
    rules: [
      {
        type: 'late_attendance',
        rules: [],
      },
      {
        type: 'early_leave',
        rules: [],
      },
      {
        type: 'extra_hours',
        rules: [],
      },
      {
        type: 'missing_hours',
        rules: [],
      },
      {
        type: 'off_days',
        rules: [
          {
            time: 'holiday',
          },
          {
            time: 'weekend',
          },
          {
            time: 'vacation',
          },
        ],
      },
      {
        type: 'absence_days',
        rules: [],
      },
    ],
  },
}

export const getters = {
  work_plans(state) {
    return state.work_plans
  },
  current_work_plan(state) {
    return state.current_work_plan
  },
}

export const mutations = {
  SET_WORK_PLANS(state, newValue) {
    state.work_plans = newValue
  },
  SET_CURRENT_WORK_PLAN(state, newValue) {
    state.current_work_plan = newValue
  },
}

export const actions = {
  // Get WorkPlans.
  getWorkPlans({ commit, dispatch }, id) {
    return workPlanRepo.get(id).then(
      (response) => {
        if (id) {
          commit('SET_CURRENT_WORK_PLAN', response.data)
        } else {
          commit('SET_WORK_PLANS', response.data)
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create WorkPlan.
  createWorkPlan({ commit, dispatch }, work_plan) {
    return workPlanRepo.post(work_plan).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data.success) {
          dispatch('notification/success', response.data.message, {
            root: true,
          })
          commit('SET_CURRENT_WORK_PLAN', response.data.data)
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete WorkPlan.
  deleteWorkPlan({ commit, dispatch }, id) {
    return workPlanRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_CURRENT_WORK_PLAN', {})
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
