import { jobTitleRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  job_titles: [],
  current_job_title: {},
}

export const getters = {
  job_titles(state) {
    return state.job_titles
  },
  current_job_title(state) {
    return state.current_job_title
  },
}

export const mutations = {
  SET_JOB_TITLES(state, newValue) {
    state.job_titles = newValue
  },
  SET_CURRENT_JOB_TITLE(state, newValue) {
    state.current_job_title = newValue
  },
}

export const actions = {
  // Get JobTitles.
  getJobTitles({ commit, dispatch }) {
    return jobTitleRepo.get().then(
      (response) => {
        commit('SET_JOB_TITLES', response.data)
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create JobTitle.
  createJobTitle({ state, commit, dispatch }, job_title) {
    return jobTitleRepo.post(job_title).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_JOB_TITLE', response.data)
          if (job_title.id) {
            commit(
              'SET_JOB_TITLES',
              state.job_titles.map((j) => {
                if (parseInt(j.id) === parseInt(job_title.id)) {
                  return response.data.data
                }
                return j
              })
            )
          } else {
            commit('SET_JOB_TITLES', [...state.job_titles, response.data.data])
          }
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete JobTitle.
  deleteJobTitle({ state, commit, dispatch }, id) {
    return jobTitleRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_JOB_TITLE', {})
          commit(
            'SET_JOB_TITLES',
            state.job_titles.filter((j) => parseInt(j.id) !== parseInt(id))
          )
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
