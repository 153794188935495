import { userRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  currentUser: JSON.parse(sessionStorage.getItem('authUser')),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    let tmp = { ...newValue }
    if (!state.currentUser && tmp?.user?.employees?.length > 0) {
      console.log('assign org', tmp.user.employees[0].organization_id)
      tmp.user.current_org = tmp.user.employees[0].organization_id
    }
    console.log(tmp?.user?.current_org)
    state.currentUser = tmp
    if (newValue) {
      sessionStorage.setItem('authUser', JSON.stringify(tmp))
    } else {
      sessionStorage.clear()
    }
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return userRepo.login(email, password).then(
      (user) => {
        commit('SET_CURRENT_USER', user)
        window.location.reload()
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },

  // Logs out the current user.
  logOut({ commit }) {
    console.log('LOGOUT')
    // eslint-disable-next-line no-unused-vars
    commit('SET_CURRENT_USER', null)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      userRepo
        .logout()
        .then((response) => {
          if (response) {
            window.location.reload()
          }
          resolve(true)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)
    return userRepo.getAuthUser().then((user) => {
      commit('SET_CURRENT_USER', user)
      return user
    })
  },
}
