import axios from 'axios'
import { authHeader } from './auth-header'

const baseDomain = process.env.VUE_APP_HRMS_API_URL
let baseURL = baseDomain

let user = JSON.parse(sessionStorage.getItem('authUser'))
if (user) {
  baseURL += `/v1/organizations/${user.user?.current_org}`
}

export default axios.create({
  baseURL,
  headers: authHeader(),
})
