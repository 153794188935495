import repository from '../repository'

const createApiRepository = (resource) => ({
  get(id = null) {
    let url = resource
    if (id) {
      url += `/${id}`
    }
    return repository.get(url).then((response) => {
      if (response.status === 200) {
        return response.data
      }
    })
  },
  post(payload) {
    let url = resource
    if (payload?.id) {
      url += `/${payload.id}`
    }
    return repository.post(url, payload)
  },
  destroy(id) {
    return repository.delete(`${resource}/${id}`)
  },
})

export default createApiRepository
