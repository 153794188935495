import repository from '../repository'

export default {
  getTree() {
    return repository.get(`/org_tree`).then(({ data }) => {
      if (data.success) {
        return data.data
      }
    })
  },
}
