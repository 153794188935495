import repository from '../repository'

export default {
  login(email, password) {
    return repository
      .post('/auth/login', { email, password })
      .then(({ data }) => {
        if (data.success) {
          return data.data
        }
      })
  },
  logout() {
    return new Promise((resolve) => {
      resolve(true)
    })
  },
  getAuthUser() {
    return repository.get('/auth/me').then((user) => {
      return user
    })
  },
  getUserByEmail(email) {
    return repository.post(`/user_email`, { email }).then((user) => {
      return user
    })
  },
}
