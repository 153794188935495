import { payrollExtraRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  payroll_extras: [],
  current_payroll_extra: {
    type: '',
    name: '',
    is_active: true,
  },
}

export const getters = {
  payroll_extras(state) {
    return state.payroll_extras
  },
  current_payroll_extra(state) {
    return state.current_payroll_extra
  },
}

export const mutations = {
  SET_PAYROLL_EXTRAS(state, newValue) {
    state.payroll_extras = newValue
  },
  SET_CURRENT_PAYROLL_EXTRA(state, newValue) {
    state.current_payroll_extra = newValue
  },
}

export const actions = {
  // Get PayrollExtras.
  getPayrollExtras({ commit, dispatch }, id) {
    return payrollExtraRepo.get(id).then(
      (response) => {
        if (id) {
          commit('SET_CURRENT_PAYROLL_EXTRA', response.data)
        } else {
          commit('SET_PAYROLL_EXTRAS', response.data)
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create PayrollExtra.
  createPayrollExtra({ commit, dispatch }, payroll_extra) {
    return payrollExtraRepo.post(payroll_extra).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_PAYROLL_EXTRA', response.data.data)
          if (payroll_extra.id) {
            commit(
              'SET_PAYROLL_EXTRAS',
              state.payroll_extras.map((j) => {
                if (parseInt(j.id) === parseInt(payroll_extra.id)) {
                  return response.data.data
                }
                return j
              })
            )
          } else {
            commit('SET_PAYROLL_EXTRAS', [
              ...state.payroll_extras,
              response.data.data,
            ])
          }
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete PayrollExtra.
  deletePayrollExtra({ commit, dispatch }, id) {
    return payrollExtraRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_PAYROLL_EXTRA', {})
          commit(
            'SET_PAYROLL_EXTRAS',
            state.payroll_extras.filter((j) => parseInt(j.id) !== parseInt(id))
          )
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
