import { departmentRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  departments: [],
  current_department: {},
}

export const getters = {
  departments(state) {
    return state.departments
  },
  current_department(state) {
    return state.current_department
  },
}

export const mutations = {
  SET_DEPARTMENTS(state, newValue) {
    state.departments = newValue
  },
  SET_CURRENT_DEPARTMENT(state, newValue) {
    state.current_department = newValue
  },
}

export const actions = {
  // Get Departments.
  getDepartments({ commit, dispatch }) {
    return departmentRepo.get().then(
      (response) => {
        commit('SET_DEPARTMENTS', response.data)
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Create Department.
  createDepartment({ commit, dispatch }, department) {
    return departmentRepo.post(department).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_CURRENT_DEPARTMENT', response.data)
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
  // Delete Department.
  deleteDepartment({ state, commit, dispatch }, id) {
    return departmentRepo.destroy(id).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        if (response.data?.success) {
          commit('SET_CURRENT_DEPARTMENT', {})
          commit(
            'SET_DEPARTMENTS',
            state.departments.filter((d) => parseInt(d.id) !== parseInt(id))
          )
        }
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
