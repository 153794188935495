import { orgRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  organization_tree: {},
}

export const getters = {
  organization_tree(state) {
    return state.organization_tree
  },
}

export const mutations = {
  SET_TREE(state, newValue) {
    state.organization_tree = newValue
  },
}

export const actions = {
  // Get Org Tree.
  getTree({ commit, dispatch }) {
    return orgRepo.getTree().then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_TREE', response)
        return response
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
