import { userRepo } from '../../helpers/hrms_backend/repositories'

export const state = {
  users: [],
}

export const getters = {
  users(state) {
    return state.users
  },
}

export const mutations = {
  SET_USER(state, newValue) {
    state.users = newValue
  },
}

export const actions = {
  // Get User By Email.
  getUserByEmail({ commit, dispatch }, { email } = {}) {
    return userRepo.getUserByEmail(email).then(
      (response) => {
        dispatch('notification/error', null, { root: true })
        commit('SET_USER', response.data)
        return response.data
      },
      (error) => {
        const message = error?.response?.data?.message ?? error
        dispatch('notification/error', message, { root: true })
      }
    )
  },
}
